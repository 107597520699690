import { default as check_45emailslUa1PNkORxMeta } from "/opt/build/repo/pages/account/check-emails.vue?macro=true";
import { default as data_45deletionlkTV07NKLbMeta } from "/opt/build/repo/pages/account/data-deletion.vue?macro=true";
import { default as delete_45confirmxb6ccKVWY4Meta } from "/opt/build/repo/pages/account/delete-confirm.vue?macro=true";
import { default as loginZlL3bdocAtMeta } from "/opt/build/repo/pages/account/login.vue?macro=true";
import { default as faqKBZt61hnJ8Meta } from "/opt/build/repo/pages/faq.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as subscribebcJTGHKFkfMeta } from "/opt/build/repo/pages/subscribe.vue?macro=true";
export default [
  {
    name: check_45emailslUa1PNkORxMeta?.name ?? "account-check-emails___en",
    path: check_45emailslUa1PNkORxMeta?.path ?? "/account/check-emails",
    meta: check_45emailslUa1PNkORxMeta || {},
    alias: check_45emailslUa1PNkORxMeta?.alias || [],
    redirect: check_45emailslUa1PNkORxMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/account/check-emails.vue").then(m => m.default || m)
  },
  {
    name: check_45emailslUa1PNkORxMeta?.name ?? "account-check-emails___de",
    path: check_45emailslUa1PNkORxMeta?.path ?? "/de/account/check-emails",
    meta: check_45emailslUa1PNkORxMeta || {},
    alias: check_45emailslUa1PNkORxMeta?.alias || [],
    redirect: check_45emailslUa1PNkORxMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/account/check-emails.vue").then(m => m.default || m)
  },
  {
    name: data_45deletionlkTV07NKLbMeta?.name ?? "account-data-deletion___en",
    path: data_45deletionlkTV07NKLbMeta?.path ?? "/account/data-deletion",
    meta: data_45deletionlkTV07NKLbMeta || {},
    alias: data_45deletionlkTV07NKLbMeta?.alias || [],
    redirect: data_45deletionlkTV07NKLbMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/account/data-deletion.vue").then(m => m.default || m)
  },
  {
    name: data_45deletionlkTV07NKLbMeta?.name ?? "account-data-deletion___de",
    path: data_45deletionlkTV07NKLbMeta?.path ?? "/de/account/data-deletion",
    meta: data_45deletionlkTV07NKLbMeta || {},
    alias: data_45deletionlkTV07NKLbMeta?.alias || [],
    redirect: data_45deletionlkTV07NKLbMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/account/data-deletion.vue").then(m => m.default || m)
  },
  {
    name: delete_45confirmxb6ccKVWY4Meta?.name ?? "account-delete-confirm___en",
    path: delete_45confirmxb6ccKVWY4Meta?.path ?? "/account/delete-confirm",
    meta: delete_45confirmxb6ccKVWY4Meta || {},
    alias: delete_45confirmxb6ccKVWY4Meta?.alias || [],
    redirect: delete_45confirmxb6ccKVWY4Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/account/delete-confirm.vue").then(m => m.default || m)
  },
  {
    name: delete_45confirmxb6ccKVWY4Meta?.name ?? "account-delete-confirm___de",
    path: delete_45confirmxb6ccKVWY4Meta?.path ?? "/de/account/delete-confirm",
    meta: delete_45confirmxb6ccKVWY4Meta || {},
    alias: delete_45confirmxb6ccKVWY4Meta?.alias || [],
    redirect: delete_45confirmxb6ccKVWY4Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/account/delete-confirm.vue").then(m => m.default || m)
  },
  {
    name: loginZlL3bdocAtMeta?.name ?? "account-login___en",
    path: loginZlL3bdocAtMeta?.path ?? "/account/login",
    meta: loginZlL3bdocAtMeta || {},
    alias: loginZlL3bdocAtMeta?.alias || [],
    redirect: loginZlL3bdocAtMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/account/login.vue").then(m => m.default || m)
  },
  {
    name: loginZlL3bdocAtMeta?.name ?? "account-login___de",
    path: loginZlL3bdocAtMeta?.path ?? "/de/account/login",
    meta: loginZlL3bdocAtMeta || {},
    alias: loginZlL3bdocAtMeta?.alias || [],
    redirect: loginZlL3bdocAtMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/account/login.vue").then(m => m.default || m)
  },
  {
    name: faqKBZt61hnJ8Meta?.name ?? "faq___en",
    path: faqKBZt61hnJ8Meta?.path ?? "/faq",
    meta: faqKBZt61hnJ8Meta || {},
    alias: faqKBZt61hnJ8Meta?.alias || [],
    redirect: faqKBZt61hnJ8Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: faqKBZt61hnJ8Meta?.name ?? "faq___de",
    path: faqKBZt61hnJ8Meta?.path ?? "/de/faq",
    meta: faqKBZt61hnJ8Meta || {},
    alias: faqKBZt61hnJ8Meta?.alias || [],
    redirect: faqKBZt61hnJ8Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___en",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index___de",
    path: indexlaFeZuYZkUMeta?.path ?? "/de",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: subscribebcJTGHKFkfMeta?.name ?? "subscribe___en",
    path: subscribebcJTGHKFkfMeta?.path ?? "/subscribe",
    meta: subscribebcJTGHKFkfMeta || {},
    alias: subscribebcJTGHKFkfMeta?.alias || [],
    redirect: subscribebcJTGHKFkfMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/subscribe.vue").then(m => m.default || m)
  },
  {
    name: subscribebcJTGHKFkfMeta?.name ?? "subscribe___de",
    path: subscribebcJTGHKFkfMeta?.path ?? "/de/subscribe",
    meta: subscribebcJTGHKFkfMeta || {},
    alias: subscribebcJTGHKFkfMeta?.alias || [],
    redirect: subscribebcJTGHKFkfMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/subscribe.vue").then(m => m.default || m)
  }
]